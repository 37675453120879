<template>
  <div>
    <WeBlockTypes
      v-on:create-block="onCreateBlock"
      v-bind:value="blocks"
      class="mb-3"
    />
    <WePageContent v-bind:blocks.sync="blocks" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
const WeBlockTypes = () => import("./components/WeBlockTypes/Index.vue");
const WePageContent = () => import("./components/WePageContent/Index.vue");

export default {
  name: "WePageEditor",
  components: {
    WeBlockTypes,
    WePageContent,
  },
  props: {
    value: {
      default: () => [],
    },
  },
  model: {
    prop: "value",
  },
  methods: {
    ...mapActions({
      getBrandList: "brand/getList",
    }),
    onCreateBlock(data) {
      this.blocks.push(data);
    },
  },
  computed: {
    blocks: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    blocks: {
      handler: function (newVal, oldVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.getBrandList();
  },
};
</script>